import { WHITE } from "../../consts/colors";

export const styles = {
  menuStyle: {
    "& .MuiMenu-paper": {
      borderRadius: "16px",
      minWidth:"250px",
      padding: "24px",
      backgroundColor: WHITE,
      boxShadow: "0px 4px 68px 0px #0000001F",
      "& .MuiList-root": {
        padding: "0px",
      },
    },
   
  },
  userDescription: {
    display: "flex",
    alignItems: "center",
    gap: "12px",
    padding: "0px 0px 12px 0px",
    cursor:"default"
  },
  userDetail: {
    display: "flex",
    flexDirection: "column",
    gap: "4px",
    paddingTop: "5px",
    cursor:"default"
  },
  nameText: {
    fontFamily: "Public Sans",
    fontSize: "14px",
    fontWeight: 600,
    lineHeight: "14px",
    textAlign: "left",
    color: "#5A5A5A",
  },
  avatarStyle: {
    height: "40px !important",
    width: "40px !important",
  },
  emailText: {
    fontFamily: "Public Sans",
    fontSize: "12px",
    fontWeight: 400,
    lineHeight: "12px",
    textAlign: "left",
    color: "#767676",
  },
  dividerStyles: {
    border: "0.5px solid #D9D9D9",
    marginBottom: "12px",
  },
  itemContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "100%",
    padding: "0px !important",
    marginBottom: "10px",
  },
  itemSubContainer: {
    display: "flex",
    alignItems: "center",
    gap: "8px",
    padding: "10px 0px",
  },
  itemActionIcon: {
    height: "16px",
    width: "16px",
    objectFit:"contain"
  },
  actionText: {
    fontFamily: "Public sans",
    fontWeight: "400",
    fontSize: "14px",
    lineHeight: "14px",
    color: "#5A5A5A",
  },
  menuIconLogo: {
    height: "25px",
    width:'25px',
  },
  avtarBox :{
    display:"flex",
    justifyContent:"center",
    alignItems:"center",
    gap:"16px"
  },
};
