import React, { useState } from "react";
import {
  Box,
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Popover,
  IconButton,
  InputAdornment,
  Select,
  MenuItem,
} from "@mui/material";
import dayjs from "dayjs";
import { ArrowDownward, ArrowUpward } from "@mui/icons-material";
import Checkbox from '@mui/material/Checkbox';
import VisibilityIcon from "@mui/icons-material/Visibility";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import Pagination from "../Pagination/Pagination";
import handleTableValues from "../../utils/handleTableValues";
import { SORT_KEYS } from "../../consts/constants";
import { HOT_PURPLE } from "../../consts/colors";
import { styles } from "./CustomTable.style";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import ClearIcon from "@mui/icons-material/Clear";
import SearchableSelect from "../SearchableSelect/SearchableSelect";

const CustomTable = ({
  columns,
  customTableContainer,
  customTableOuterContainer,
  data,
  toggleSorting,
  sorting,
  pagesInfo,
  handlePageChange,
  handleRowClick,
  customBoxContainer,
  tableContainer,
  initialRow,
  error,
  initialRowCommonStyles,
  }) => {

  function splitLabelWithLineBreak(label) {
    const parts = label.split("&");
    if (parts.length === 2) {
      return (
        <>
          {parts[0].trim()} &amp;
          <br />
          {parts[1].trim()}
        </>
      );
    }
    return label;
  }
  const Active = ({ status, column, data }) => {
    if (column.isDot === false) {
      return status;
    }
    if (status === "success") {
      return (
        <Box sx={styles.activeContainer}>
          <Box sx={styles.sucess}></Box>
        </Box>
      );
    } else if (status === "danger") {
      return (
        <ConditionalTooltipWrapper
          Modal={column?.ToolTipModal}
          data={data}
          column={column}
        >
          <Box sx={styles.activeContainer}>
            <Box sx={styles.danger}></Box>
          </Box>
        </ConditionalTooltipWrapper>
      );
    } else if (status === "warning") {
      return (
        <Box sx={styles.activeContainer}>
          <Box sx={styles.warning}></Box>
        </Box>
      );
    }
  };
  const ConditionalTooltipWrapper = ({ children, data, Modal, column }) => {
    if (Modal) {
      return (
        <TooltipWrapper
          {...(column.ToolTipMode ? { ToolTipMode: true } : { darkMode: true })}
          title={<Modal data={data} />}
          placement="bottom"
          arrow
        >
          {children}
        </TooltipWrapper>
      );
    }
    return children;
  };

  const Actions = ({ column, data }) => {
    let { actions } = column;
    return (
      <Box sx={styles.actionsContainer}>
        {actions.length &&
          actions.map(
            ({ src, alt, onClick, MuiIcon, isShow, name, bgColor }, idx) => {
              const showAction = isShow ? isShow(data) : true;
              if (showAction) {
                return (
                  <TooltipWrapper
                    key={idx}
                    backgroundColor={bgColor || HOT_PURPLE}
                    darkMode={true}
                    title={name}
                    placement="top"
                    arrow
                  >
                    {MuiIcon ? (
                      <MuiIcon
                        sx={styles.actionIcon}
                        onClick={() => onClick(data)}
                      />
                    ) : (
                      <Typography
                        key={idx}
                        component={"img"}
                        sx={styles.actionItems}
                        src={src}
                        alt={alt}
                        onClick={() => {
                          onClick(data);
                        }}
                      />
                    )}
                  </TooltipWrapper>
                );
              }
              return (<Box>
                </Box>
              )
            }
          )}
      </Box>
    );
  };

  function getTableCell(column, value, idx, data) {
    switch (column.label) {
      case "Status":
        return <Active status={value} column={column} data={data} />;
      case "Actions":
        return <Actions column={column} data={data} />;
      default:
        if (column.displayEyeIcon && value !== "--") {
          return (
            <Box sx={styles.largerCellContainer}>
              <Typography sx={styles.largerText}>{value}</Typography>
              <VisibilityIcon
                sx={styles.actionItems}
                onClick={(e) =>
                  column.onEyeIconClick && column.onEyeIconClick(data, e)
                }
              />
            </Box>
          );
        }
        if(column.isCheckBox){
          return (
            <Checkbox
            sx={styles.checkBoxStyle}
            checked={value}
           onClick={(e)=>{
             e.stopPropagation()
             const newValue=e.target.checked;
              data?.handleClick && data.handleClick({
                newValue,data
              })
           }}
            inputProps={{ 'aria-label': 'controlled' }}
          />
          )
        }
        if (column.isDataTypeImage) {
          const imageUrl = !!column.imageData
            ? column.imageData(data)
            : data[column.value] || column.defaultImage;
          return imageUrl ? (
            <Typography
              component="img"
              src={imageUrl}
              alt={column.label}
              sx={styles.imageStyle}
            />
          ) : column.defaultImageComponent ? (
            column.defaultImageComponent
          ) : (
            <Typography>--</Typography>
          );
        }
        return column?.isEllipsis && value !== "--" ? (
          <TooltipWrapper title={value} placement={"bottom"}>
            <Typography sx={styles.ellipsis}>{value}</Typography>
          </TooltipWrapper>
        ) : (
          value
        );
    }
  }

  const RenderComponent = ({ item }) => {
    const {
      component,
      type,
      value,
      setValue,
      required,
      isReadOnly,
      autoCompleteOff,
      hasEyeIcon,
      options,
      name,
      validate,
      isTextTransform,
      handleTogglePasswordVisibility,
      VisibilityOff,
      showPassword,
      customInputStyles,
      Visibility,
      customAutoCompleteStyle,
      customJsx: CustomJsx,
    } = item;

    const [calendarOpen, setCalenderOpen] = useState(false);
    const [anchorEl, setAnchorEl] = useState(null);

    if (component === "TextField") {
      return (
        <TextField
          type={type}
          // inputProps={
          //   isTextTransform ? { style: { textTransform: "capitalize" } } : {}
          // }
          sx={{ ...styles.input }}
          fullWidth
          value={value}
          onChange={(e) => {
            setValue(e.target.value);
          }}
          disabled={isReadOnly || false}
          required={required || false}
          helperText={error ? error : ""}
          onBlur={() => {
            if (validate) {
              validate();
            }
          }}
          autoComplete={autoCompleteOff ? "new-password" : ""}
          InputProps={{
            endAdornment: hasEyeIcon ? (
              <InputAdornment position="end">
                <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                  {showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              </InputAdornment>
            ) : null,
          }}
        />
      );
    } else if (component === "Select") {
      return (
        <>
          <Select
            fullWidth
            value={value}
            sx={{ ...styles.selectInput }}
            onChange={(e) => setValue(e.target.value)}
            required={required || false}
            MenuProps={{ PaperProps: { sx: { maxHeight: 200 } } }}
          >
            {options?.map(({ value, label }, optionIndex) => (
              <MenuItem value={value} key={optionIndex}>
                {label}
              </MenuItem>
            ))}
          </Select>
          <Box sx={styles.errorContainer}>
            {error && !!error[name] && (
              <Typography sx={styles.validityError}>{error[name]}</Typography>
            )}
          </Box>
        </>
      );
    } else if (component === "calendar") {
      return (
        <>
          <TextField
            onClick={(event) => {
              setCalenderOpen(name);
              setAnchorEl(event.currentTarget);
            }}
            required={required}
            readOnly
            fullWidth
            sx={{ ...styles.input, ...customInputStyles }}
            value={value ? dayjs(value).format("MM/DD/YYYY") : ""}
            InputProps={{
              endAdornment: value ? (
                <IconButton
                  size="small"
                  onClick={() => {
                    setValue("");
                  }}
                >
                  <ClearIcon />
                </IconButton>
              ) : undefined,
            }}
            onBlur={() => {
              if (validate) {
                validate();
              }
            }}
          />
          <Box sx={styles.errorContainer}>
            {error && !!error[name] && (
              <Typography sx={styles.validityError}>{error[name]}</Typography>
            )}
          </Box>
          <Popover
            open={calendarOpen === name}
            onClose={() => {
              setCalenderOpen(false);
              setAnchorEl(null);
            }}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "left",
            }}
          >
            <LocalizationProvider dateAdapter={AdapterDateFns}>
              <CalendarPicker
                minDate={new Date()}
                date={value ? new Date(value) : new Date()}
                onChange={(newDate) => {
                  setValue(newDate);
                  setCalenderOpen(false);
                  setAnchorEl(null);
                }}
              />
            </LocalizationProvider>
          </Popover>
        </>
      );
    } else if (component === "Custom") {
      return CustomJsx;
    } else if (component === "AutoComplete") {
      return (
        <>
        <SearchableSelect
        isTextboxGray
        searchableSelectDropdownCustomStyles={{...customAutoCompleteStyle}}
        selectedValue={value}
        setSelectedOption={(value) => {
          setValue(value);
        }}
        keyName={"label"}
        keyValue={"value"}
        menuOptions={options}
        />
        <Box sx={styles.errorContainer}>
            {error && !!error[name] && (
              <Typography sx={styles.validityError}>{error[name]}</Typography>
            )}
            </Box>
      </>
        );
      }
  };

  return (
    <Box sx={{ ...customBoxContainer }}>
      <Paper sx={{ ...styles.tableParentContainer, ...customTableContainer }}>
        <TableContainer
          sx={{
            ...styles.tableOuterContainer,
            ...customTableOuterContainer,
            ...tableContainer,
          }}
        >
          <Table
            sx={{
              ...styles.tableContainer,
              ...customTableOuterContainer,
              ...{},
            }}
            stickyHeader
            aria-label="sticky table"
          >
            <TableHead>
              <TableRow style={styles.tableHeader}>
                {columns.map((column) => {
                  const sortValue = column?.sortValue
                    ? column?.sortValue
                    : column.value;
                  const isActiveSort = sorting?.key === sortValue;
                  return (
                    <TableCell
                      key={column?.id}
                      align={"center"}
                      sx={{ ...styles.tableHeader, minWidth: column.minWidth }}
                    >
                      {column.isSortable ? (
                        <Box sx={styles.outerFilterContainer}>
                          <Typography sx={styles.labelStyle}>
                            {splitLabelWithLineBreak(column.label)}
                          </Typography>
                          <Box sx={styles.headerFilter}>
                            <Box
                              onClick={() =>
                                toggleSorting(sortValue, SORT_KEYS.ASC)
                              }
                            >
                              <ArrowUpward
                                sx={{
                                  ...styles.arrow,
                                  ...(isActiveSort &&
                                    sorting?.type === 1 &&
                                    styles.greenArrow),
                                }}
                              />
                            </Box>
                            <Box
                              onClick={() =>
                                toggleSorting(sortValue, SORT_KEYS.DESC)
                              }
                            >
                              <ArrowDownward
                                sx={{
                                  ...styles.arrow,
                                  ...(isActiveSort &&
                                    sorting?.type === -1 &&
                                    styles.greenArrow),
                                }}
                              />
                            </Box>
                          </Box>
                        </Box>
                      ): column?.isCheckBox? (
                        <Checkbox
                        indeterminate={!!column?.indeterminate}
                        onClick={()=>{
                          column?.handleClick && column?.handleClick()
                        }}
                        checked={false}
                      sx={styles.checkBoxStyle}
                        />
                      ) :
                       (
                        <Typography sx={styles.labelStyle}>
                          {splitLabelWithLineBreak(column?.label)}
                        </Typography>
                      )}
                    </TableCell>
                  );
                })}
              </TableRow>
            </TableHead>

            <TableBody>
            {initialRow && (
  <TableRow>
    {initialRow.map((item, index) => {
      return (
        <TableCell
          key={`${item.id}`}
          sx={initialRowCommonStyles ? initialRowCommonStyles : {}}
        >
          <RenderComponent item={item} />
        </TableCell>
      );
    })}
  </TableRow>
)}
              {!!data?.length &&
                data.map((row, idx) => {
                  return (
                    <TableRow
                     key={row?.id}
                      onClick={() => {
                        handleRowClick && handleRowClick(row);
                      }}
                      role="checkbox"
                      tabIndex={-1}
                      sx={{
                        ...styles.tableRow,
                        ...(idx % 2 ? styles.evenRow : {}),
                        ...(handleRowClick && styles.pointer),
                      }}
                    >
                      {columns.map((column) => {
                        const value = column?.formatter
                          ? column.formatter(row)
                          : handleTableValues({
                              data: row[column.value],
                              isDataTypeDate: column.isDataTypeDate,
                              isDataTypeAmount: column.isDataTypeAmount,
                              isDataTypeChecked:column.isCheckBox,
                              dateFormat: column.dateFormat,
                            });
                        return (
                          <TableCell
                            key={column.id}
                            align="center"
                            sx={{
                              ...styles.tableCell,
                              maxWidth: column?.maxWidth,
                              ...(typeof column?.customTableCellStyle ===
                              "function"
                                ? column.customTableCellStyle(row)
                                : column.customTableCellStyle || {}),
                              ...(column.isTextCapitalize
                                ? styles.capitalizeText
                                : {}),
                            }}
                          >
                            {
                              <Box
                                sx={
                                  column.isEllipsis
                                    ? {
                                        maxWidth: column?.maxWidth,
                                        ...styles.ellipsis,
                                      }
                                    : {}
                                }
                              >
                                {getTableCell(column, value, idx, row)}
                              </Box>
                            }
                          </TableCell>
                        );
                      })}
                    </TableRow>
                  );
                })}
              {!data?.length && (
                <TableRow sx={styles.emptyTableContainer}>
                  <TableCell colSpan={columns?.length}>
                    No data to show
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <Pagination pagesInfo={pagesInfo} handlePageChange={handlePageChange} />
      </Paper>
    </Box>
  );
};
CustomTable.defaultProps = {
  customBoxContainer: {},
  tableContainer: {},
  customTableContainer: {},
};

export default CustomTable;
