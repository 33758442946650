export const UsersListColumns = [
  {
    id: 1,
    value: "first_name",
    label: "Name",
    maxWidth: "180px",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 2,
    value: "last_name",
    label: "Last Name",
    maxWidth: "180px",
    minWidth: "150px",
    isSortable: true,
    customTableCellStyle: {
      textTransform: "capitalize",
    },
  },
  {
    id: 3,
    value: "email",
    label: "Email",
    minWidth: "200px",
    maxWidth: "240px",
  },
  {
    id: 4,
    value: "phone_number",
    label: "Phone",
    maxWidth: "180px",
    minWidth: "150px",
    isEllipsis: true
  },
];


export const usersTableFilterMap = {
  first_name: "first_name",
  last_name: "last_name",
};
