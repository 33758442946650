import React, {  useEffect,  useState } from "react";
import { Box, Typography } from "@mui/material";
import { useNavigate } from "react-router-dom";

import Base from "../../Layout/Base";
import CustomBanner from "../../components/CustomBanner/CustomBanner";
import CustomButton from "../../components/CustomButton/CustomButton";
import CustomLoader from "../../components/CustomLoader/CustomLoader";
import CustomTable from "../../components/CustomTable/CustomTable";
import SearchAndFilter from "../../components/SearchAndFilter/SearchAndFilter";
import ViewUserDetails from "../ViewUserDetails/ViewUserDetails";
import useFetch from "../../api/common/useFetch";
import { urlService } from "../../utils/urlService";
import handleSearchWithDebouncing from "../../utils/handleSearchWithDebouncing";
import { getValidRowPerPage } from "../../utils/getValidRowPerPage";
import { handleRowPerPageChange } from "../../utils/handleRowPerPageChange";
import { getSelectedDataDetails } from "../../utils/getSelectedDataDetails";
import navigations from "../../routes/routeNames";
import { UsersListColumns, usersTableFilterMap } from "./UsersListConstants";
import { GET_ADMIN_LIST, SEND_CREDS } from "../../api/apiEndpoints";
import {
  SHOW_SAVE_DATA_TOAST_DURATION,
  SHOW_TOAST_MESSAGE_DURATION,
} from "../../consts/toastShowDuration";
import { commonStyles } from "../../assests/commonStyles";
import styles from "./UsersListStyles";
import { SORT_KEYS } from "../../consts/constants";
import SendCredential from "../SendCredential/SendCredential";
import usePostData from "../../utils/apiService/Hooks/usePostData";

const UsersList = () => {
  const navigate = useNavigate();
  const [selectedUserDetails, setSelectedUserDetails] = useState(null);
  const [searchText, setSearchText] = useState(
    urlService.getQueryStringValue("search") || ""
  );
  const [userSuccess, setUserSuccess] = useState(false);
  const [currentPage, setCurrentPage] = useState(
    +urlService.getQueryStringValue("page") || 1
  );
  const [rowsPerPage, setRowsPerPage] = useState(getValidRowPerPage());
  const [sorting, setSorting] = useState(() => {
    const sortbyString = urlService.getQueryStringValue("sort-by");
    if (!!sortbyString && sortbyString?.split("-")?.length === 2) {
      const sortArray = sortbyString?.split("-");
      const type = sortArray[1] === SORT_KEYS.ASC ? 1 : -1;
      const key = sortArray[0];
      return { key, type };
    }
    return { key: "first_name", type: 1 };
  });
  const [deleteUserSuccess, setDeleteUserSuccess] = useState(false);
  const [selectedUsersCreds, setSelectedUsersCred] = useState([]);
  const [generateCredsModal, setGenerateCredsModal] = useState(false);
  const [successCredMessage, setSuccessCredMessage] = useState("");

  const sortParamters = () => {
    const sortbyQuery = urlService.getQueryStringValue("sort-by");
    if (!!sortbyQuery) {
      const sortArray = sortbyQuery.split("-");
      const sortType = sortArray[1];
      const sortKey = sortArray[0];
      return { sortType, sortKey };
    }
    return { sortType: SORT_KEYS.ASC, sortKey: "first_name" };
  };

  const {
    data: usersInfo,
    isLoading: isGettingUserList,
    error: errorGettingUserList,
    setData: setUserList,
    fetchData: getUsersList,
    totalCount,
    setTotalCount,
  } = useFetch({
    url: `${GET_ADMIN_LIST}`,
    queryParamsObject: {
      perPage: rowsPerPage,
      search: searchText,
      page: currentPage,
      sortOrder: sortParamters()?.sortType,
      sortField: sortParamters()?.sortKey,
    },
    callback: () => {
      setCurrentPage(1);
    },
  });

  const userList = usersInfo?.data?.users || []


  const {
    loading: isSendingCreds,
    error: errorWhileSendingCreds,
    setError: setErrorWhileSendingCreds,
    handlePost,
  } = usePostData({});

  const sendCreds = (password) => {
    handlePost({
      url: `${SEND_CREDS}`,
      payload: {
        password,
        id: selectedUsersCreds,
      },
      successCallback: () => {
        setGenerateCredsModal(false);
        setSelectedUsersCred([]);
        setSuccessCredMessage("Credentials  sent successfully ");
      },
    });
  };

  const handlePageChange = (pageNumber) => {
    const totalpages = Math.ceil(totalCount / rowsPerPage);
    if (pageNumber >= 1 && pageNumber <= totalpages) {
      getUsersList({
        queryParamsObject: {
          perPage: rowsPerPage,
          search: searchText,
          page: pageNumber,
          sortOrder: sortParamters()?.sortType,
          sortField: sortParamters()?.sortKey,
        },
      });
      setCurrentPage(pageNumber);
      urlService.setQueryStringValue("page", pageNumber);
    }
  };

  const handleRowChange = (rowCount) => {
    handleRowPerPageChange({
      rowCount,
      fetchData: () =>
        getUsersList({
          queryParamsObject: {
            perPage: rowCount,
            search: searchText,
            page: 1,
            sortOrder: sortParamters()?.sortType,
            sortField: sortParamters()?.sortKey,
          },
        }),
      setRowsPerPage,
      setCurrentPage,
    });
  };

  const handleChangeText = (event) => {
    handleSearchWithDebouncing({
      searchText: event.target.value,
      prevSearchText: searchText,
      setSearchText: setSearchText,
      getResult: () =>
        getUsersList({
          queryParamsObject: {
            perPage: rowsPerPage,
            search: event.target.value,
            page: 1,
            sortOrder: sortParamters()?.sortType,
            sortField: sortParamters()?.sortKey,
          },
        }),
      setCurrentPage: setCurrentPage,
    });
  };

  const handleSortChange = (sortingFilter, currentSortType) => {
    const sortKey = usersTableFilterMap[sortingFilter];
    const type = currentSortType === SORT_KEYS.ASC ? 1 : -1;
    setSorting({ type, key: sortKey });
    setCurrentPage(1);
    urlService.removeParam("page");
    urlService.setQueryStringValue(
      "sort-by",
      `${sortingFilter}-${currentSortType}`
    );
    getUsersList({
      queryParamsObject: {
        perPage: rowsPerPage,
        search: searchText,
        page: 1,
        sortOrder: currentSortType,
        sortField: sortKey,
      },
    });
  };

  useEffect(() => {
    if (userList?.length > 0) {
      if (
        getSelectedDataDetails({ dataList: userList, searchId: "user-id" })
          ?.length
      ) {
        setSelectedUserDetails(
          getSelectedDataDetails({ dataList: userList, searchId: "user-id" })[0]
        );
      } else {
        urlService.removeParam("user-id");
      }
    }
  }, [userList]);

  const checkAllusers = () => {
    const allUsers = userList.map((item) => item.id);
    setSelectedUsersCred((prevSelected) => {
      const newSelectedUsers = allUsers.filter(userId => !prevSelected.includes(userId));
      return [...prevSelected, ...newSelectedUsers]; 
    });
  };

  const isAllListSelected = () =>
  userList.every((item) => selectedUsersCreds.includes(item.id));


  const newEntery = {
    id: 0,
    label: "Checked",
    isCheckBox: true,
    indeterminate:
     isAllListSelected() && userList?.length !== 0,
    value: "credentials",
    handleClick: function () {
      if (this.indeterminate) {
        this.indeterminate = false;
        const updatedSelectedUsersCreds = selectedUsersCreds.filter(userId => !userList.some(item => item.id === userId));
        setSelectedUsersCred(updatedSelectedUsersCreds)
      } else {
        this.indeterminate = true;
        checkAllusers();
      }
    },
  };

  // const updatedColumns = [newEntery, ...UsersListColumns];
  const updatedColumns = [...UsersListColumns];


  const isUserChecked = (userId) => {
    return selectedUsersCreds.some((item) => item === userId);
  };
  const updatedUserList = userList.map((item) => {
    return {
      ...item,
      credentials: isUserChecked(item.id),
      isCheckBox: true,
      value: "credentials",
      handleClick: ({ data, newValue }) => {
        if (newValue) {
          const newSelectedArray = [...selectedUsersCreds];
          newSelectedArray.push(data?.id);
          setSelectedUsersCred(newSelectedArray);
        } else {
          const newSelectedArray = selectedUsersCreds.filter((item) => {
            return item !== data?.id;
          });
          setSelectedUsersCred(newSelectedArray);
        }
      },
    };
  });

  return (
    <Base customStyles={{ ...commonStyles.tableContainer, ...{} }}>
      <Box>
        <SearchAndFilter
          headerTitle={"Users"}
          search={searchText}
          searchPlaceHolder={"Search User"}
          handleChangeText={handleChangeText}
        />
      </Box>
      <Box sx={styles.tableOuterContainer}>
        <Box sx={styles.userActionContainer}>
          <CustomButton
            classes={styles.createUserBtn}
            onClick={() => navigate(navigations.CREATE_USER)}
          >
            <Typography sx={styles.buttonText}>Create Users</Typography>
          </CustomButton>
          {/* <CustomButton
            classes={styles.createUserBtn}
            onClick={() => {
              setGenerateCredsModal(true);
            }}
          >
            <Typography sx={styles.buttonText}>Generate Credentials</Typography>
          </CustomButton> */}
        </Box>
        {!isGettingUserList && !errorGettingUserList && (
          <CustomTable
            customTableOuterContainer={styles.customTableOuterContainer}
            columns={updatedColumns}
            data={updatedUserList}
            pagesInfo={{
              currentPage: currentPage,
              total: totalCount,
              rowsperPage: rowsPerPage,
              handleRowPerPage: handleRowChange,
            }}
            handlePageChange={handlePageChange}
            handleRowClick={(rowData) => {
              setSelectedUserDetails(rowData);
              urlService.setQueryStringValue("user-id", rowData.id);
            }}
            toggleSorting={handleSortChange}
            sorting={sorting}
          />
        )}
      </Box>
      {!!selectedUserDetails && (
        <ViewUserDetails
          userDetails={selectedUserDetails}
          closeUserDetailsModal={() => {
            setSelectedUserDetails(null);
            urlService.removeParam("user-id");
          }}
          userList={userList}
          setUserList={setUserList}
          listToBeDisplayed={userList}
          setListToBeDisplayed={setUserList}
          setDeleteUserSuccess={setDeleteUserSuccess}
          setTotalCount={setTotalCount}
          setUserSuccess={setUserSuccess}
        />
      )}
      {userSuccess && (
        <Box sx={styles.toastContainer}>
          <CustomBanner
            openSnackbar={
              userSuccess &&
              setTimeout(() => {
                setUserSuccess("");
              }, 2000)
            }
            hideDuration={SHOW_TOAST_MESSAGE_DURATION}
            snackbarMessage={"User details updated Successfully"}
            severity="success"
            autoHideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
          />
        </Box>
      )}
      <Box sx={styles.toastContainer}>
        <CustomBanner
          openSnackbar={deleteUserSuccess}
          hideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
          snackbarMessage={"User Deleted Successfully"}
          severity="success"
          autoHideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
          onClose={() => {
            setDeleteUserSuccess(false);
          }}
        />
      </Box>
      {generateCredsModal && (
        <SendCredential
          handleCloseModal={() => {
            setGenerateCredsModal(false);
          }}
          onSubmit={(password) => {
            sendCreds(password);
          }}
          loading={isSendingCreds}
        />
      )}
      <CustomBanner
        hideDuration={SHOW_SAVE_DATA_TOAST_DURATION}
        openSnackbar={successCredMessage}
        onClose={() => {
          setSuccessCredMessage("");
        }}
        severity={"success"}
        snackbarMessage={successCredMessage}
      />

      <CustomBanner
        hideDuration={SHOW_TOAST_MESSAGE_DURATION}
        openSnackbar={errorWhileSendingCreds}
        onClose={() => {
          setErrorWhileSendingCreds("");
        }}
        severity={"error"}
        snackbarMessage={errorWhileSendingCreds}
      />
      {isGettingUserList && <CustomLoader customHeight={styles.loaderStyle} />}
    </Base>
  );
};

export default UsersList;
