import React, {  useState } from "react";
import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import VisibilityOffIcon from '../../img/eyeOffIcon.png'

import CustomButton from "../CustomButton/CustomButton";
import MaskedInput from "react-text-mask";
import TooltipWrapper from "../TooltipWrapper/TooltipWrapper";
import UploadImage from "../UploadImage/UploadImage";
import {
  AMERICANEXPRESS,
  CVC,
  EXPIRYDATE,
  OTHERCARDS,
} from "../../consts/constants";
import { APP_PRIMARY_THEME_COLOR } from "../../consts/colors";
import { styles } from "./EditViewFormStyle";
import "./style.css";
import dayjs from "dayjs";
import { CalendarPicker, LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { TextareaAutosize } from "@mui/core";
import { makeStyles } from "@mui/styles";
import PhotoGallery from "../PhotoGallery/PhotoGallery";
import pdfIcon from "../../img/pdfIcon.png";
import ClearIcon from "@mui/icons-material/Clear";
import ImageOrInitials from "../Initials/Initials";
import SearchableSelect from "../SearchableSelect/SearchableSelect";

const useStyles = makeStyles({
  textarea: {
    width: "100%",
    border: "1.5px solid lightgrey",
    backgroundColor: "white",
    padding: "10px",
    "&:focus": {
      border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
      outline: "none",
    },
  },
});

const EditViewForm = ({
  isEditable,
  columns,
  isThreeColumns,
  formData,
  setFormData,
  isDisabled,
  isCreate,
  onCancelClick,
  onSubmitClick,
  secondBtnTitle,
  onClickSecondBtn,
  isSecondButton,
  handleRemoveImage,
  handleFileUpload,
  customFormStyles,
  isloading,
  errorForm: error,
  isReadOnly,
  handleTogglePasswordVisibility,
  noSubmitButton,
  formRef,
  id
}) => {
  const UploadImageComponent = ({
    uploadKey,
    isUploadingImage,
    isGettingImageUrl,
    uploadImagePercentage,
    errorWhileGettingImageUrl,
    errorWhileUploadingImage,
    customImageStyles,
  }) => {
    const RenderUploadImage = () => {
      const isUploading = isUploadingImage || isGettingImageUrl;
      const uploadPercentage = uploadImagePercentage;
      const errorWhileUpload =
        errorWhileUploadingImage || errorWhileGettingImageUrl;
      return (
        <UploadImage
          openCropViewAfterImageSelection
          onDeleteImage={(idx) => handleRemoveImage(uploadKey, idx)}
          errorWhileUpload={errorWhileUpload}
          fileUploadResult={formData.logo}
          handleFileUpload={({ file }) => handleFileUpload({ file, uploadKey })}
          isUploadingImageToServer={isUploading}
          uploadPercentage={uploadPercentage}
          shouldOpenInModal
          customImageStyles={customImageStyles}
        />
      );
    };
    return (
      <Box sx={styles.documentContainer}>
        <RenderUploadImage />
      </Box>
    );
  };
  const [anchorEl, setAnchorEl] = useState(null);
  const [CalendarOpen, setCalenderOpen] = useState(false);
  const handleCalendarChange = (newDate, name) => {
    setFormData((prevData) => ({
      ...prevData,
      [name]: dayjs(newDate).format("YYYY-MM-DD"),
    }));
    handleCalendarClose();
  };
  const handleCalendarClose = () => {
    setAnchorEl(null);
    setCalenderOpen(false);
  };

  const classes = useStyles();

  const isRequiredMissing = () => {    
    const missing = columns.some((item) => {
      const value = formData[item?.name];
      return item.required && !value;
    });
  
    return missing;
  };

  const [showPassword,setShowPassword]=useState(false);


  return (
    <Typography
      component={"form"}
      ref={formRef}
      sx={{ ...styles.outer, ...customFormStyles }}
      onSubmit={onSubmitClick}
      id={id}
    >
      <Box sx={{ ...styles.outerContainer, ...customFormStyles }}>
        <Box>
          {isEditable || isReadOnly ? (
            <Grid container spacing={isThreeColumns ? 3 : 2}>
              {columns?.map(
                (
                  {
                    label,
                    required,
                    name,
                    customLabelStyles,
                    customInputStyles,
                    type,
                    component,
                    options,
                    isFull,
                    isUploadingImage,
                    uploadImagePercentage,
                    errorWhileUploadingImage,
                    isGettingImageUrl,
                    errorWhileGettingImageUrl,
                    uploadKey,
                    noLabel,
                    onclick,
                    validate,
                    isDisabled,
                    placeHolder,
                    maskOptions,
                    onFocus,
                    onHover,
                    MuiIcon,
                    image,
                    customOuterContainerStyles,
                    customJsx,
                    isTextTransform,
                    autoCompleteOff,
                    customImageStyles,
                    hasEyeIcon,
                    minRows,
                    uploadDocumentPercentage,
                    maxDate,
                    clearErrorOnChange,
                    SelectcustomStyles,
                    menuPropStyles,
                    handleChange,
                    autoCompleteStyles,
                    noErrorContainer,
                  readOnly:notEditable
                  },
                  index
                ) => (
                  <Grid
                    item
                    xs={12}
                    sm={isFull ? 12 : 6}
                    md={isFull ? 12 : isThreeColumns ? 4 : 6}
                    key={index}
                  >
                    {!noLabel && (
                      <Typography
                        component="label"
                        aria-required={required}
                        sx={{ ...styles.label, ...customLabelStyles }}
                      >
                        {label}
                      </Typography>
                    )}
                    {component === "TextField" ? (
                      <>
                        <TextField
                          type={
                            !hasEyeIcon ? type : showPassword ? "text" : type
                          }
                          // inputProps={
                          //   isTextTransform
                          //     ? { style: { textTransform: "capitalize" } }
                          //     : {}
                          // }
                          sx={{ ...styles.input, ...customInputStyles }}
                          fullWidth
                          value={formData[name]}
                          onChange={(e) => {
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: e.target.value,
                            }));
                            if (clearErrorOnChange) {
                              clearErrorOnChange();
                            }
                          }}
                          disabled={isReadOnly || notEditable || isDisabled?.()||false}
                          required={required || false}
                          onBlur={() => {
                            if (validate) {
                              validate();
                            }
                          }}
                          autoComplete={autoCompleteOff ? "new-password" : ""}
                          InputProps={{
                            endAdornment: (hasEyeIcon && !!formData[name]) ? (
                              <InputAdornment position="end">
                                <IconButton
                                  onClick={()=>{
                                    setShowPassword((prev)=>!prev)
                                  }}
                                  edge="end"
                                >
                                  {showPassword ? (
                                      <Typography 
                                      component={"img"}
                                      sx={styles.passwordVisibilityIcon}
                                      src={VisibilityOffIcon}
                                      />
                                  ) : (
                                    <Typography 
                                    component={"img"}
                                    sx={styles.passwordVisibilityIcon}
                                    src={VisibilityOffIcon}
                                    />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ) : null,
                          }}
                        />

                         {!noErrorContainer && <Box sx={styles.errorContainer}>
                        {error && error[name] && (
                          <Typography sx={styles.validityError}>
                            {error[name]}
                          </Typography>
                          
                          )}
                          </Box>}
                      </>
                    ) : component === "calendar" ? (
                      <>
                        <TextField
                          onClick={(event) => {
                            setCalenderOpen(name);
                            setAnchorEl(event.currentTarget);
                          }}
                          required={required}
                          readOnly
                          fullWidth
                          sx={{ ...styles.input, ...customInputStyles }}
                          value={
                            formData[name]
                              ? dayjs(formData[name]).format("MM/DD/YYYY")
                              : ""
                          }
                          InputProps={{
                            endAdornment: formData[name] ? (
                              <IconButton
                                size="small"
                                onClick={() =>
                                  setFormData((prevData) => ({
                                    ...prevData,
                                    [name]: "",
                                  }))
                                }
                              >
                                <ClearIcon />
                              </IconButton>
                            ) : undefined,
                          }}
                          helperText={error?.[name] ? error?.[name] : ""}
                          onBlur={() => {
                            if (validate) {
                              validate();
                            }
                          }}
                        />
                        <Popover
                          open={CalendarOpen === name}
                          anchorEl={anchorEl}
                          onClose={() => setCalenderOpen(false)}
                          anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "left",
                          }}
                        >
                          <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <CalendarPicker
                              date={
                                formData[name]
                                  ? new Date(formData[name])
                                  : new Date()
                              }
                              onChange={(newDate) =>
                                handleCalendarChange(newDate, name)
                              }
                              {...(maxDate ? { maxDate } : {})}
                            />
                          </LocalizationProvider>
                        </Popover>
                      </>
                    ) : component === "TextArea" ? (
                      <TextareaAutosize
                        value={formData[name]}
                        minRows={minRows || 5}
                        className={classes.textarea}
                        onChange={(e) =>
                          setFormData((prevData) => ({
                            ...prevData,
                            [name]: e.target.value,
                          }))
                        }
                      />
                    ) : component === "Custom" ? (
                      <Box>{customJsx}</Box>
                    ) : component === "uploadImage" ? (
                      <Box>
                        <UploadImageComponent
                          handleRemoveImage={handleRemoveImage}
                          handleFileUpload={handleFileUpload}
                          isUploadingImage={isUploadingImage}
                          uploadImagePercentage={uploadImagePercentage}
                          errorWhileUploadingImage={errorWhileUploadingImage}
                          isGettingImageUrl={isGettingImageUrl}
                          errorWhileGettingImageUrl={errorWhileGettingImageUrl}
                          uploadKey={uploadKey}
                          customImageStyles={customImageStyles}
                          uploadDocumentPercentage={uploadDocumentPercentage}
                        />
                      </Box>
                    ) : component === "header" ? (
                      <>
                        <Typography
                          component="label"
                          aria-required={required}
                          sx={{ ...styles.label, ...customLabelStyles }}
                          onClick={() => {
                            if (onclick) {
                              onclick();
                            }
                          }}
                        >
                          {label}
                        </Typography>
                      </>
                    ) : component === "Action" ? (
                      <Typography
                        onClick={() => {
                          if (onclick) {
                            onclick();
                          }
                        }}
                      >
                        {MuiIcon && (
                          <TooltipWrapper title={name} placeHolder="right">
                            <MuiIcon />
                          </TooltipWrapper>
                        )}

                        {image && (
                          <Typography
                            component={"img"}
                            src={image}
                            sx={styles.img}
                          />
                        )}
                      </Typography>
                    ) : component === "CardNumber" ? (
                      <Box>
                        <MaskedInput
                          className="Maskinput"
                          onBlur={() => {
                            if (validate) {
                              validate();
                            }
                          }}
                          mask={
                            ["37", "34"].includes(
                              formData["cardNumber"]
                                ?.split("")
                                ?.splice(0, 2)
                                ?.join("")
                            )
                              ? AMERICANEXPRESS
                              : OTHERCARDS
                          }
                          guide={false}
                          placeholderChar={"\u2000"}
                          placeholder="Debit / Credit Card Number"
                          name="cardNumber"
                          value={formData["cardNumber"] || ""}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: e.target.value,
                            }))
                          }
                        />
                        <Box sx={styles.minHeight} >
                      
                        {error && error[name] && (
                          <Typography sx={styles.validityError}>
                            {error[name]}
                          </Typography>
                        )}
                      </Box>
                      </Box>
                    ) : component === "CardExpiryDate" ? (
                      <Box>
                        <MaskedInput
                          mask={EXPIRYDATE}
                          className="Maskinput"
                          guide={false}
                          name="expiry"
                          placeholderChar={"\u2000"}
                          placeholder="MM/YY"
                          value={formData["cardExpiryDate"] || ""}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: e.target.value,
                            }))
                          }
                          onBlur={() => {
                            if (validate) {
                              validate();
                            }
                          }}
                        />
                        {error && error[name] && (
                          <Typography sx={styles.validityError}>
                            {error[name]}
                          </Typography>
                        )}
                      </Box>
                    ) : component === "CVV" ? (
                      <Box>
                        <MaskedInput
                          className="Maskinput"
                          mask={CVC}
                          guide={false}
                          name="cardCode"
                          type="password"
                          placeholderChar={"\u2000"}
                          placeholder="Card Verification Value (CVV)"
                          value={formData["cvv"]}
                          onChange={(e) =>
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: e.target.value,
                            }))
                          }
                          onBlur={() => {
                            if (validate) {
                              validate();
                            }
                          }}
                        />
                        {error && error[name] && (
                          <Typography sx={styles.validityError}>
                            {error[name]}
                          </Typography>
                        )}
                      </Box>
                    ) : component === "CheckBox" ? (
                      <Box sx={{ ...customOuterContainerStyles }}>
                        <FormControlLabel
                          required={required || false}
                          control={
                            <Checkbox
                              disabled={isDisabled?.() || false}
                              checked={formData[name]}
                            />
                          }
                          sx={{
                            ...customLabelStyles,
                            span: {
                              color: APP_PRIMARY_THEME_COLOR,
                              fontSize:"14px"                            },
                          }}
                          onChange={(e) => {
                            handleChange? handleChange(e.target.checked):
                            setFormData((prevData) => ({
                              ...prevData,
                              [name]: e.target.checked,
                            }));
                          }}
                          label={label}
                        />
                      </Box>
                    ):component==="AutoComplete"?(
                      <SearchableSelect
                      isTextboxGray
                      searchableSelectDropdownCustomStyles={autoCompleteStyles}
                      selectedValue={formData[name]}
                      setSelectedOption={(value) => {
                        setFormData((prevData) => ({
                          ...prevData,
                          [name]: value,
                        }));
                    
                      }}
                      keyName={"label"}
                      keyValue={"value"}
                      menuOptions={options}
                      />
                    )
                    : (

                      <Select
                        fullWidth
                        value={formData[name]}
                        sx={{ ...styles.selectInput,...SelectcustomStyles }}
                        onChange={(e) => {
                          setFormData((prevData) => ({
                            ...prevData,
                            [name]: e.target.value,
                          }));
                          if (validate) {
                            validate(e.target.value);
                          }
                        }}
                        MenuProps={{ PaperProps: { sx: menuPropStyles?menuPropStyles: { } } }}
                        required={required || false}
                      >
                        {options?.map(({ value, label }, optionIndex) => (
                          <MenuItem value={value} key={optionIndex}>
                            {label}
                          </MenuItem>
                        ))}
                      </Select>
                    )}
                  </Grid>
                )
              )}
            </Grid>
          ) : (
            <Grid
              container
              spacing={{ xs: 2, md: 3 }}
              columns={{ xs: 4, sm: 12, md: 12 }}
            >
              {columns.map((otherOptions, index) => (
                <Grid
                  item
                  xs={12}
                  sm={isThreeColumns ? (otherOptions.isFull ? 12 : 6) : 12}
                  md={isThreeColumns ? (otherOptions.isFull ? 12 : 4) : 12}
                  key={index}
                  children
                  sx={
                    isThreeColumns
                      ? {
                          ...styles.detailsSubContainer,
                          ...(otherOptions.subContainer &&
                            otherOptions.subContainer),
                        }
                      : {}
                  }
                >
                  <RenderComponent
                    otherOptions={otherOptions}
                    formData={formData}
                    isThreeColumns={isThreeColumns || false}
                  />
                </Grid>
              ))}
            </Grid>
          )}

          <Box sx={styles.buttonContainer}>
            {isEditable && !noSubmitButton && (
              <CustomButton
                type="submit"
                isDisabled={isDisabled || isRequiredMissing(columns)}
                classes={{ ...styles.commonBtnStyle }}
                showLoader={isloading}
              >
                <Typography sx={styles.buttonText}>Save</Typography>
              </CustomButton>
            )}
            {isCreate &&  !noSubmitButton  && (
              <CustomButton
                type="submit"
                classes={{ ...styles.commonBtnStyle }}
                onClick={onSubmitClick}
                isDisabled={isDisabled || isRequiredMissing(columns)}
                showLoader={isloading }
              >
                <Typography>Create</Typography>
              </CustomButton>
            )}

            {isSecondButton && (
              <CustomButton
                classes={{ ...styles.commonBtnStyle, ...styles.cancelBtn }}
                onClick={onCancelClick || onClickSecondBtn}
              >
                <Typography sx={styles.cancelText}>
                  {secondBtnTitle ? secondBtnTitle : "Cancel"}
                </Typography>
              </CustomButton>
            )}
          </Box>
        </Box>
      </Box>
    </Typography>
  );
};
const formatValue = (column, value) => {
if(column.isDateType){
  return (
    <Typography sx={styles.detailText}>
    {!!value ?   dayjs(value).format("MM/DD/YYYY")  : "--"}
  </Typography>
  )
}
  if ((column.isDocument || column.pdfVideo || column.isImage) && !!value) {
    const documentArray =
      typeof value === "object"
        ? value
        : value?.includes(",")
        ? value.split(",")
        : [value];
    const modifiedDocArray = documentArray?.map((doc) => {
      if (doc?.endsWith("pdf")) {
        return { type: "pdf", url: doc, src: pdfIcon };
      } else if (doc?.endsWith("mp4")) {
        return {
          type: "video",
          url: doc,
          src: doc,
        };
      }
      return { type: "image", url: doc, src: doc };
    });

    return (
      <Box sx={styles.photoContainer}>
        <PhotoGallery
          hideIconDelete
          photos={modifiedDocArray}
          customImageStyles={styles.documentImage}
          onPhotoClick={(photo) => {
            if (photo.type === "pdf") {
              window.open(photo.url, "_blank");
            }
          }}
        />
      </Box>
    );
  }

  
  return (
    <Typography sx={{...styles.detailText,...(true  && styles.removeTextTransform)}}>
      {column.isImage ? (
        <ImageOrInitials
        />
      ) : (
        value !== null && value !== undefined ? value.toString() : '--'
      )}
    </Typography>
  );
};

const RenderComponent = ({ otherOptions, formData, isThreeColumns }) => {
  const {
    label,
    value,
    isImage,
    customLabelStyle,
    customValueStyle,
    isDocument,
    formatter,
    pdfVideo,
    isDateType,
    defaultImage,
    isTextTransform
  } = otherOptions;

  if ( (!isDateType && ((!isDocument && !isImage && !pdfVideo))) || !formData[value] ) {
    return (
      <Box
        sx={{
          ...styles.columnContainer,
          ...(isThreeColumns && { border: "none" }),
        }}
      >
        <Typography sx={{ ...styles.labelStyle, ...customLabelStyle }}>
          {label}
        </Typography>
       {isImage && !formData[value] ? (
        !!defaultImage?(
          <Typography
          component={"img"}
          src={defaultImage}
          sx={styles.img}
          />
        ):
       (
         <ImageOrInitials
         customStyles={{height:"50px",width:"50px",marginTop:"-10px !important"}}
         />
       )
       ):(
        <Typography sx={{ ...customValueStyle,...{textTransform:"capitalize"},...(isTextTransform===false && styles.removeTextTransform) }}>
        {formatter && !!formData[value]
          ? formatter(formData[value])
          : formData[value] || "--"}
      </Typography>
       )
        }
      </Box>
    );
  } else {
    return (
      <Box sx={{ margin:"10px",
    }}>
        <Typography sx={{ ...styles.labelStyle, ...customLabelStyle }}>
          {label}
        </Typography>
        {formatValue(otherOptions, formData[value])}
      </Box>
    );
  }
};

export default EditViewForm;
