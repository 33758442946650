import React from 'react'
import { Box, IconButton } from "@mui/material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { styles } from './BackButtonStyles';

const BackButton = ({ handleClick ,customStyles}) => {
  return (

    <Box sx={{...styles.backButtonContainer,...customStyles}}
    onClick={handleClick}
    >
    <IconButton 
    >
      <ArrowBackIcon sx={styles.backButton} />
    </IconButton>
  </Box>
  )
}

export default BackButton
