import {
  GREY_CHATEAU,
  APP_PRIMARY_THEME_COLOR,
  ONVISIA_GREY,
  TEXT_RED,
  WHITE,
} from "../../consts/colors";

export const styles = {
  outerContainer: {
    backgroundColor: WHITE,
    borderRadius: "10px",
    minWidth: "100%",
    margin: "20px 20px 0px 20px",
    padding: "20px 25px",
    position:"relative",
    zIndex:1
  },
  documentContainer: {
    display: "flex",
    gap: "14px",
  },
  cardNumberInputField: {
    width: "100%",
    height: "39px",
    padding: "0px 14px",
    fontSize: "12px",
    lineHeight: "18px",
    fontWeight: 500,
    borderRadius: "4px",
    border: "1.5px solid lightgrey",
  },
 
  detailsSubContainer: {
    borderBottom: `1px solid ${ONVISIA_GREY}`,
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    paddingBottom: "6px",
    wordBreak: "break-word",
  },
  labelStyle: {
    marginBottom: "20px",
    color: APP_PRIMARY_THEME_COLOR,
    fontSize: "14px",
  },
  columnContainer: {
    margin:"10px",
    fontSize: "18px",
    fontWeight: "600",
  },
  commonBtnStyle: {
    width: "120px",
    fontWeight:"600"
  },
  cancelBtn: {
    backgroundColor: WHITE,
    border: `1px solid ${GREY_CHATEAU}`,
    "&:hover": {
      backgroundColor: WHITE,
    },
  },
  outer: {
    display: "flex",
    justifyContent: "center",
  },
  heading: {
    fontWeight: "600",
    fontSize: 16,
    color: APP_PRIMARY_THEME_COLOR,
  },
  actionContainer: {
    marginTop: "4px",
    display: "flex",
    justifyContent: "flex-end",
    gap: "10px",
  },
  input: {
    "& .MuiInputBase-root": {
      height: "45px",
      borderRadius: "5px",
      fontSize:"14px",
      "& fieldset": {
        border: "1.5px solid lightgrey",
      },
      "&.Mui-focused fieldset": {
        border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
      },
      "&:hover fieldset": {
        border:` 1.5px solid  lightgrey`,
      },
    },
    '& p':{
      color:'red',
    },
  },
  selectInput: {
    height: "45px",
    borderRadius: "5px",
    "&:hover fieldset": {
      border: `none`,
    },

    "&.MuiOutlinedInput-root": {
      "& fieldset": {
        border: "1.5px solid lightgrey",
      },
      "&.Mui-focused fieldset": {
        border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
      },
      fontSize:"14px"
    },
  },
  label: {
    display: "block",
    fontSize: "14px",
    marginBottom: "10px",
    color: APP_PRIMARY_THEME_COLOR,
    lineHeight:"20px",
    fontWeight:"500"
  },
  footer: {
    display: "flex",
    gap: "10px",
    marginTop: "40px",
  },
  buttonContainer: {
    display: "flex",
    marginTop: "30px",
    alignItems: "flex-end",
    gap: "10px",
  },
  cancelText: {
    color: APP_PRIMARY_THEME_COLOR,
  },
  img: {
    height: "90px",
    width: "90px",
    borderRadius: "5px",
  },
  validityError: {
    fontSize: "12px",
    lineHeight: "18px",
    color: TEXT_RED,
  },
  logoStyle: {
    width: "80px",
    height: "80px",
  },
  photoContainer: {
    display: "flex",
  },
  documentImage: {
    width: "100px",
    height: "100px",
  },
  detailText: {
    fontSize: "14px",
    textTransform: "capitalize",
  },
  buttonText:{
    fontWeight:"600"
  },
  errorContainer:{
    minHeight:"20px"
  },
  passwordVisibilityIcon: {
    width: "21px",
    height: "21px",
  },
  removeTextTransform:{
    textTransform: "none",
  }
 
};
