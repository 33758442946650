import React, { useContext } from "react";
import { AppBar, Box, Toolbar, Typography } from "@mui/material";
import CustomLoader from "../CustomLoader/CustomLoader";
import logoutImage from "../../img/logout.svg";
import useNavigateScreen from "../../CustomHooks/useNavigateScreen";
import useLogoutUser from "../../utils/apiService/Hooks/useLogoutUser";
import { UserContext } from "../../globalContext/user/userProvider";
import navigations from "../../routes/routeNames";
import logo from "../../img/ciiHorizontalLogo.png";

import { styles } from "./Header.styles";
import getRole from "../../auth/getRole";
import { Roles } from "../../consts/constants";
import UserMenu from "../UserMenu/UserMenu";
const Header = () => {
  const { navigateScreen } = useNavigateScreen();
  const { isLoggingUserOut, logoutHandler } = useLogoutUser();
  const [userData] = useContext(UserContext);
  const { userInfo } = userData;
  const role = getRole();
  const isDentist = role === Roles.dentist;
  const isAdmin = role === Roles.admin;
  return (
    <AppBar position="sticky" elevation={0} sx={styles.appbarStyle}>
      <Toolbar sx={[styles.toolBarStyle, isDentist && { padding: "12px" }]}>
       
        <Typography
          onClick={() =>
            isAdmin
              ? navigateScreen(navigations.ACCOUNTS)
              : navigateScreen(navigations.HOME)
          }
          component={"img"}
          src={logo}
          alt="App Logo"
          style={styles.imagelogo}
        />
        <Box sx={styles.rightContainer}>
          {!!userInfo && Object.keys(userInfo)?.length && (
            <Box sx={styles.userInfoContainer}>
              <Box sx={styles.userInfo}>
                <Typography sx={styles.welcomeText}>
                 {!isDentist ? "Hi,":userInfo?.data?.data?.salutation} { `${userInfo?.data?.data?.first_name +"  "}` 
                 }
                  &nbsp;

                 <Typography sx={styles.welcomeText} component={"span"}>{userInfo?.data?.data?.last_name}</Typography>
                </Typography>
                {!isDentist &&  <Typography sx={styles.userType}>
                   Admin
                </Typography>}

              </Box>
            </Box>
          )}
          {isDentist && <UserMenu userInfo={userInfo} />}
          {isAdmin && (
  <>
    {isLoggingUserOut && (
      <Box>
        <CustomLoader
          customHeight={styles.loaderStyles}
          loaderIconStyles={styles.loaderIconStyles}
        />
      </Box>
    )}
    {!isLoggingUserOut && (
      <Box sx={styles.logoutContainer}>
        <Typography
          component="img"
          src={logoutImage}
          sx={styles.logoutBtn}
          alt="Logout Icon"
          onClick={() => logoutHandler({})}
        />
      </Box>
    )}
  </>
)}
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export default Header;
