import React, { useEffect, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Box, Typography } from "@mui/material";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DeleteIcon from '@mui/icons-material/Delete';

import UtilityModal from "../UtilityModal/UtilityModal";
import { styles } from "./PhotoGalleryStyles";
import VideoPlayerHsl from "../VideoPlayerHsl/VideoPlayerHsl";

const PhotoGallery = ({
  photos,
  includeSliderKeys,
  customImageStyles,
  onPhotoClick,
  hideIconDelete,
  onRemoveImage,
}) => {
  const horizontalScrollRef = useRef(null);

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(0);
  const [selectedVideo, setSelectedVideo] = useState(null);

  const [showModal, setShowModal] = useState(false);

  const slideLeft = () => {
    horizontalScrollRef.current.scrollLeft =
      horizontalScrollRef.current?.scrollLeft - 500;
  };

  const slideRight = () => {
    horizontalScrollRef.current.scrollLeft =
      horizontalScrollRef.current?.scrollLeft + 500;
  };

  const renderableImages = () => {
    return photos.map((photo) => {
      return {
        ...photo,
        src: photo?.src ? photo.src : photo,
      };
    });
  };

  const imagesToBeRendered = renderableImages();
  useEffect(() => {
    return () => {
      setSelectedPhotoIndex(0);
      setSelectedPhotoIndex(-1);
      setShowModal(false);
    };
  }, []);

const isVideo=(photo)=>{
  return photo.endsWith('.m3u8') || photo.endsWith('.mp4')
}

  return (
    <>
      <Box >
        {photos?.length ? (
          <Box sx={styles.container} ref={horizontalScrollRef}>
            {imagesToBeRendered?.map((photo, idx) => (
              <Box key={idx} sx={{ position: "relative" }}>
                {!isVideo(photo?.src)  ? <Typography
                  sx={{ ...styles.imageStyles, ...customImageStyles }}
                  component="img"
                  alt="image"
                  src={photo?.src}
                  onClick={() => {
                    if (photo.type === "pdf") {
                      onPhotoClick && onPhotoClick(photo);
                      return;
                    }
                    setShowModal(true);
                    setSelectedPhotoIndex(idx);
                    setSelectedVideo(null);
                  }}
                />
                :
                (
                <VideoPlayerHsl
                isControlDisable
                customVideoStyles={styles.customVideoStyles}
                handleClick={() => {
                      setSelectedVideo(photo?.src)
                      setSelectedPhotoIndex(idx);
                    }}
                    src={photo.src}
                    showPlayIcon={true}
                />
                )
                }
                {!hideIconDelete && (
                  <Box sx={styles.deleteIconContainer}>
                    <DeleteIcon sx={styles.deleteIcon} onClick={() => {onRemoveImage(idx)
                    && setSelectedVideo(null)
                    }} />
                  </Box>
                )}
              </Box>
            ))}
          </Box>
        ) : null}
       
        {includeSliderKeys && (
          <Box sx={styles.arrowKeysContainer}>
            <Box sx={styles.arrowKeysBox}>
              <ChevronLeftIcon
                sx={{
                  ...styles.iconStyles,
                  ...(horizontalScrollRef.current?.scrollLeft === 0
                    ? styles.leftArrowDisabledStyles
                    : {}),
                }}
                onClick={slideLeft}
              />
              <ChevronRightIcon sx={styles.iconStyles} onClick={slideRight} />
            </Box>
          </Box>
        )}
      </Box>
      {showModal &&  (
        <UtilityModal
          openModal={showModal || selectedVideo}
          setCloseModal={setShowModal}
          selectedPhotoIndex={selectedPhotoIndex}
          photos={imagesToBeRendered}
          video={ selectedVideo }
          setSelectedVideo={setSelectedVideo}
        />
      )}
{selectedVideo &&  (
        <UtilityModal
          openModal={selectedVideo}
          setCloseModal={setShowModal}
          selectedPhotoIndex={selectedPhotoIndex}
          photos={imagesToBeRendered}
          video={ selectedVideo }
          setSelectedVideo={setSelectedVideo}
        />
      )}
    </>
  );
};

PhotoGallery.propTypes = {
  photos: PropTypes.array,
  includeSliderKeys: PropTypes.bool,
  customImageStyles: PropTypes.object,
  hideIconDelete: PropTypes.bool,
  onRemoveImage: PropTypes.func,
};

export default PhotoGallery;
