import {
    GREY95,
    APP_PRIMARY_THEME_COLOR,
    SILVER_GREY,
    METALLIC_SILVER,
  } from "../../consts/colors";
    const hideBorders = {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        border: "none",
      },
      "&:hover fieldset": {
        border: "none",
      },
      "&.Mui-focused fieldset": {
        border: "none",
      },
    },
  };
  
  const customSrollBar = {
    "&::-webkit-scrollbar": {
      width: "0.4em",
    },
    "&::-webkit-scrollbar-track": {
      boxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      webkitBoxShadow: "inset 0 0 6px rgba(0,0,0,0.00)",
      width: "7px",
      borderRadius: "12px",
      background: SILVER_GREY,
    },
    "&::-webkit-scrollbar-thumb": {
      backgroundColor: "rgba(0,0,0,.1)",
      borderRadius: "12px",
      background: METALLIC_SILVER,
    },
  };
    export const styles = {
    hideBorders,
    customSrollBar,
    autoCompleteStyles: {
      "& + .MuiAutocomplete-popper .MuiAutocomplete-paper": {
        width: "140%",
        border: `1.5px solid ${"lightgrey"}`,
        ...customSrollBar,
        "@media (max-width: 1200px)": {
          width: "100%",
        },
      },
      "& + .MuiAutocomplete-popper .MuiAutocomplete-listbox": {
        backgroundColor: GREY95,
        ...customSrollBar,
      },
      "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
        fontSize: "14px",
        lineHeight: "20px",
        height: "3rem",
        border: `1.5px solid ${"lightgrey"}`,
      },
   
        "&.Mui-focused MuiAutocomplete-root": {
            border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
          },
          "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                {
                    border: `1.5px solid  ${APP_PRIMARY_THEME_COLOR}`,
                },
    },
  
    textfieldStyles: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
        lineHeight: "22px",
        height:"45px",
      },
      ...hideBorders,
    },
      autocompleteGrayBox: {
      borderRadius: "4px",
      border: `1.5px solid ${"lightgrey"}`,
      textTransform:"capitalize",
      "& + .MuiAutocomplete-popper .MuiAutocomplete-paper": {
        width: "100%",
        maxHeight: "250px",
      },
      "& + .MuiAutocomplete-popper .MuiAutocomplete-listbox": {
        maxHeight: "200px",
        ...customSrollBar,
      },
      "& + .MuiAutocomplete-popper .MuiAutocomplete-option": {
        fontSize: "14px",
        lineHeight: "22px",
        minHeight: "40px",
        textTransform:"capitalize",
      },
    },
  
    searchableBox: {
      "& .MuiInputBase-input": {
        fontSize: "14px",
           padding: "0px !important",
        paddingLeft: "5px !important",
        height: "24px",
        // textTransform:"capitalize",
      },
      ...hideBorders, 
    },
  
    loaderOnInputStyles: {
      color: APP_PRIMARY_THEME_COLOR,
    },
  };