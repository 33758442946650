import accountsImage from "../../img/accounts.svg";
import coursesImage from "../../img/courses.svg";
import onlineProgramsImage from '../../img/OnlinePrograms.png';
import handsOnImage from '../../img/HandsOn.png'
import saasIcon from "../../img/saas.svg";
import userIcon from "../../img/users.svg";
import navigations from "../../routes/routeNames";


export const sideBarIcons = [
  {
    img: accountsImage,
    navigateTo: navigations.ACCOUNTS,
    name: "Accounts",
    id: 1,
  },
  {
    img:coursesImage,
    navigateTo:navigations.LIVE_EXTERNSHIP,
    name:"Live Patient Externships",
    id:2
  },
  {
    img: handsOnImage,
    navigateTo: navigations.CADAVER_PROGRAM,
    name: "Hands-On Cadaver Programs",
    id: 3,
  },
  {
    img: onlineProgramsImage,
    navigateTo: navigations.ONLINE_PROGRAM,
    name: "Online Programs",
    id: 4,
  },

  {
    img:saasIcon,
    navigateTo: navigations.SAAS,
    name:"saas",
    id:5
  },
  {
    img: userIcon,
    navigateTo: navigations.USERS,
    name: "Users",
    id: 6,
  },
];
