import React, { useRef } from "react";
import { Box, Typography } from "@mui/material";
import ReactHlsPlayer from "react-hls-player";
import videoPlayer from "../../img/videoPlayer.svg";
import { styles } from "./VideoPlayerStyle";

const VideoPlayer = ({
  src,
  handleClick,
  customVideoStyles,
  showPlayIcon,
}) => {
  const playerRef = useRef();

  return (
    <Box sx={styles.container}>
      <Typography
        component={"video"}
        controls
        ref={playerRef}
        src={src}
        onClick={() => {
          handleClick && handleClick();
        }}
        controlsList="nodownload"
        style={{ ...customVideoStyles }}
      />
      {showPlayIcon && (
        <Typography
          component={"img"}
          src={videoPlayer}
          sx={styles.videoPlayer}
          onClick={() => {
            handleClick && handleClick();


          }}
        />
      )}
    </Box>
  );
};
export default VideoPlayer;
