import {
  BORDER_GREY,
  BROWN_HEADING,
  HOT_PURPLE,
  LIGHT_RED,
  RED_RATING,
  WARM_ORANGE,
  WHITE,
} from "../../consts/colors";

const styles = {
  userContainer: {
    gap: "14px",
    padding: "0px 16px",
  },
  closeIconContainer: {
    display: "flex",
    justifyContent: "end",
    position: "relative",
  },
  closeButton: {
    padding: "0px",
    display: "flex",
    justifyContent: "end",
    position: "absolute",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "transparent",
    },
  },
  closeIcon: {
    fill: BROWN_HEADING,
    width: "24px",
    height: "24px",
    marginTop: "0.5rem",
  },
  customToolBarStyles: {
    height: "84px",
    position: "sticky",
    marginTop: "32px",
  },
  actionContainer: {
    marginTop: "4px",
    display: "flex",
    justifyContent: "end",
    alignItems: "center",
    gap: "16px",
    marginBottom:"1rem"
  },
  editDeleteContainer: {
    display: "flex",
    gap: "10px",
  },
  commonBtnStyle: {
    width: "120px",
    fontWeight:"600"
  },
  deleteBtn: {
    backgroundColor: RED_RATING,
    "&:hover": {
      backgroundColor: LIGHT_RED,
    },
  },
  userDetailsParentContainer: {
    display: "flex",
    justifyContent: "center",
  },
  userDetailsContainer: {
    backgroundColor: WHITE,
    borderRadius: "10px",
    minWidth: "100%",
    marginBottom: "14px",
  },
  userDetailsFormContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  labelStyle: {
    color: HOT_PURPLE,
    fontSize: "16px",
    fontWeight: "500",
  },
  capitalizedText: {
    textTransform: "capitalize",
  },
  columnContainer: {
    borderBottom: `1px solid ${BORDER_GREY}`,
    padding: "10px",
    display: "flex",
    flexDirection: "column",
    gap: "10px",
  },
  lastColumn: {
    borderBottom: "none",
  },
  errorContainer:{
    width:"100%",
    // minWidth:"30vw",
    // "@media (max-width : 1000px)": {
    //   minWidth:"50vw",
    //   },   
  },
  customToolbar: {
    height: "84px",
    position: "sticky",
  },
  label: {
    display: "block",
    fontSize: "14px",
    marginBottom: "10px",
    color: WARM_ORANGE,
    lineHeight:"20px",
    fontWeight:"500"
  },
  orangeCheckBoxLabel:{
    color: WARM_ORANGE 
  },
  toastContainer: {
    position: "relative",
    width: "100%",
    marginTop: "10px",
  },
};

export default styles;
