import {
  Box,
  Checkbox,
  FormControlLabel,
  Grid,
  Typography,
} from "@mui/material";
import PropTypes from "prop-types";

import { styles } from "./CustomCheckBoxStyles";
import { checkBoxGroup } from "../EditUserDetails/EditUserDetailsConstant";

const CustomCheckBoxGroup = ({ columns, setFormData, formData,isReadOnly}) => {
  return (
    <Grid container spacing={1}>
      {columns?.map(
        (
          {
            label,
            required,
            name,
            customLabelStyles,
            isDisabled,
            customOuterContainerStyles,
            handleChange
          },
          index
        ) => (
          <Grid item xs={12} sm={12} md={12} key={index}>
            <Box sx={{ ...customOuterContainerStyles }}>
              <FormControlLabel
                required={required || false}
                control={
                  <Checkbox
                    disabled={isDisabled?.()|| isReadOnly || false}
                    checked={formData[name]}
                  />
                }
                sx={styles.checkBoxStyle}              
                onChange={(e) => {
                (handleChange && handleChange(e))||
                 setFormData((prevData) => ({
                    ...prevData,
                    [name]: e.target.checked,
                  }));
                }}
                label={
                  <Typography
                    sx={{ ...styles.labelStyle, ...customLabelStyles }}
                  >
                    {label}
                  </Typography>
                }
              />
            </Box>
          </Grid>
        )
      )}
    </Grid>
  );
};

checkBoxGroup.propTypes = {
  columns:PropTypes.array,
  setFormData:PropTypes.func,
  formData:PropTypes.object
};
export default CustomCheckBoxGroup;
