import getRole from "../auth/getRole";
import { Roles } from "../consts/constants";
import navigations from "./routeNames";

export const permissionNames = {
  deleteSaaS: "deleteSaaS",
  saas: "saasAdmin",
  accounts:"account",
  deleteAccount:"deleteAccount",
  user:"user",
  deleteUsers:"deleteUser",
  deleteCourse:"deleteCourse",
  course:"course",
  categories:"categories",
  deleteCategories:"deleteCategories"
};

const checkAuthorisation = ({ permissions, requestedRoute }) => {
  const doesContainRoute = (route) => {
    return requestedRoute?.toLowerCase().includes(route?.toLowerCase());
  };
  const role=getRole()
  
  if((doesContainRoute("dentist") || doesContainRoute("home")) && role!==Roles.dentist){
    return false;
  }


  if (permissions) {
    if (
      doesContainRoute(navigations.APPOINTMENTS) ||
      doesContainRoute(navigations.PATIENTS) ||
      doesContainRoute(navigations.PROVIDERS) ||
      doesContainRoute(navigations.REVIEWS) ||
      doesContainRoute(navigations.REVENUE)
    ) {
      return permissions?.includes(permissionNames.fad);
    }
    if (doesContainRoute(navigations.PRACTISES)) {
      return permissions?.includes(permissionNames.practices);
    }
    if (doesContainRoute(navigations.SAAS)) {
      return permissions?.includes(permissionNames.saas);
    }
    if (doesContainRoute(navigations.ACCOUNTS)) {
      return permissions?.includes(permissionNames.accounts);
    }
    if (doesContainRoute(navigations.USERS)) {
      return permissions?.includes(permissionNames.user);
    }
    if(doesContainRoute(navigations.COURSES)){
      return permissions?.includes(permissionNames.course);
    }
    if(doesContainRoute(navigations.CREATE_COURSE)){
      return permissions?.includes(permissionNames.course);
    }
    if(doesContainRoute(navigations.COURSE_TYPE)){
      return permissions?.includes(permissionNames.course);
    }
    if(doesContainRoute(navigations.CREATE_CATEGORY)){
      return permissions?.includes(permissionNames.categories);
    }
    if(  (doesContainRoute(navigations.LIVE_EXTERNSHIP) || doesContainRoute(navigations.ONLINE_PROGRAM) || doesContainRoute(navigations.CADAVER_PROGRAM)) ){
      return permissions?.includes(permissionNames.course);
    }
    if(doesContainRoute(navigations.CATEGORY_TYPE)){
      return permissions?.includes(permissionNames.categories);
    }

  
  
    return true;
  }
  if(doesContainRoute(navigations.SUBCAT) && role===Roles.dentist){
    return true;
  }
  if(doesContainRoute(navigations.HOME) && role===Roles.dentist){
    return true;
  }
  if(doesContainRoute(navigations.DENTIST_SETTING) && role===Roles.dentist){
    return true;
  }


  return false;
};

export default checkAuthorisation;
